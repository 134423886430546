//@import "./theme/all.css";
//@import "./theme/kendo-mui-theme-overrides.scss";

// Workaround for DropDownList not popping up when it's in a dialog box
html,
body,
#root{
  height: 100%
}
.k-animation-container {
  position: absolute;
  overflow: hidden;
  z-index: 10003;
}

.k-button.k-primary {
  background: #0c71a5 !important;
  border: 1px solid #0c71a5 !important;
  box-sizing: border-box !important;
  border-radius: 2px !important;
}

.k-calendar .k-state-selected .k-link {
  border: 1px solid #0c71a5 !important;
  background: #0c71a5 !important;
}

.k-today, .k-time-now {
  color: #0088ce !important;
}